.tabList {
  @apply px-[30px] border-b border-alto;

  @screen tablet {
    @apply p-0;
  }
}

.tabPanels {
  @apply m-0;

  @screen tablet {
    @apply mt-[20px];
  }
}

.tab {
  @apply rounded-t font-os;
  @apply focus:outline-none;
  @apply text-xs p-2;
  height: 39px;

  @screen tablet {
    @apply px-5 py-2 text-sm;
  }
}

.tabselected {
  @apply border-b-2 border-apple-light;
  text-shadow: 0 0 1px black;
}

.tabnotselected {
  @apply font-normal;
}

.tabContentWrap {
  @apply hidden;
}

.tabContentWrapSelected {
  @apply block;
}
