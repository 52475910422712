.inner {
  background: #ffffff;
  border: 1px solid #e9eff5;
  padding: 16px;
}

.tail {
  width: 100%;
  height: 4px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    margin: 0 0 0 -2px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #31792b;
  }
}

.green_balloon .tail {
  border-top: 2px solid #31792b;
}

.orange_balloon .tail {
  border-top: 2px solid #b5770c;

  &:after {
    border-top-color: #b5770c;
  }
}

.listingItemImage {
  float: left;
  width: 268px;
  height: 165px;
  border-radius: 3px;
  overflow: hidden;
}

.listingItemDetail {
  display: inline-block;
  width: 268px;
  font-family: "Roboto" !important;
}

.listingTitle {
  font-weight: 400;
  font-size: 14px;
  color: #263238;
  text-transform: lowercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 8px 0 0 0;

  &:first-letter {
    text-transform: uppercase;
  }
}

.location {
  font-weight: 400;
  font-size: 12px;
  color: #64707c;
  margin: 8px 0 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.listingProperties {
  margin: 8px 0 2px 0;

  > span {
    font-weight: 400;
    font-size: 12px;
    color: #64707c;
    margin-right: 8px;
    line-height: 16px;
    display: inline-block;

    > i {
      font-size: 16px;
      margin-right: 4px;
      vertical-align: top;
    }
  }
}

.listingPriceInfo {
  font-weight: 500;
  font-size: 18px;
  color: #263238;
  margin: 8px 0;

  p {
    margin: 0;
  }

  * {
    font-weight: 500 !important;
  }
}

.phoneButton {
  text-decoration: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 6px 8px;
    margin: 0;
    background: #52ca46;
    border: none;
    border-radius: 4px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.75;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    outline: none;
  }

  i {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* //////////////////////////////////// */

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  gap: 10px;
  background-color: #fff;
}

.image {
  width: 238px;
  height: 149px;
  border-radius: 3px;
  overflow: hidden;
  object-fit: cover;
  float: left;
}

.rightPart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 149px;
  width: 284px;
  padding: 2px 0;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  text-transform: lowercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0 5px 0;
  width: 270px;

  &:first-letter {
    text-transform: uppercase;
  }
}

.companyName {
  color: #000;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  margin: 5px 0 8px;
}

.informationRow {
  display: flex;
  align-items: center;
  min-height: 19px;
  color: #000;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 10px;
}

.informationRow span:not(:first-child) {
  border-left: 1px solid #d8d8d8;
  padding-left: 8px;
  margin-left: 8px;
}

.projectPrice {
  position: absolute;
  bottom: 0;
  width: 284px;
}

.price {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.price span {
  font-size: 11px;
  font-weight: 400;
}

.askPrice {
  color: #58b847;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.squareMeterPrice {
  width: 100%;
  min-height: 16px;
  color: #000;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

.salesCompleted {
  display: flex;
  gap: 5px;
  align-items: center;
  text-transform: none !important;

  svg {
    width: 15px;
    height: 15px;
  }
}

.phone_button {
  button {
    font-family: "Open Sans";
    position: absolute;
    bottom: 12px;
    left: 255px;
    width: 146px;
    height: 40px;
    border-radius: 40px;
    background-color: #58b847;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: none;
    z-index: 10000;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}
