.wrapper {
  max-width: 800px;
  display: block;
  padding-top: 12px;
  padding-right: 12px;
}

.wrapper h2 {
  @apply font-bold text-standarts-black p-0;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 15px;
}

.wrapper li,
.wrapper p {
  @apply text-standarts-black p-0;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 8px;
}

.wrapper ul {
  @apply list-disc;
  padding-left: 12px;
}
