.wrapper {
  @apply invisible opacity-0 absolute left-0 rounded transition ease-in-out delay-200 z-30 border border-alto;
  background-color: theme("colors.standarts.white");
}

.wrapperOpened {
  @apply visible opacity-100;
}

.wrapper > li {
  @apply hover:bg-alto;
  border-bottom: 1px solid theme("colors.wild-sand");
}

.wrapperForUser {
  left: -35px;
}

.wrapper > li:last-child {
  @apply border-none;
}

.wrapper a {
  @apply block whitespace-nowrap cursor-pointer;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 19px;
  color: theme("colors.standarts.black");
}
