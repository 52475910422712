.wrapper {
  @apply flex justify-between w-full items-center;
  height: 70px;
  background-color: theme("colors.standarts.white");
  padding: 0 20px;
  border-bottom: 2px solid rgba(34, 52, 69, 0.1);
}

.wrapperIsSticky {
  @apply sticky left-0 top-0 z-[110];
}

.inner {
  @apply flex flex-row items-start;
}

.logoWrap {
  @apply flex items-center;
  width: 108px;
  height: 70px;
}

.actionWrap {
  @apply flex flex-row items-center h-full;
}

.subscriptionWrap {
  @apply relative h-full;
}

.subMenuWrap {
  @apply absolute right-0;
}

.highlighter {
  @apply font-bold cursor-pointer h-full flex items-center;
  font-size: 14px;
  line-height: 19px;
  color: theme("colors.apple.light");
  padding: 5px 25px;
}

.highlighterForUser {
  color: theme("colors.standarts.black");
  font-weight: 600;
}

.icon {
  @apply inline-block transition-transform;
  margin-left: 2px;
}

.iconTransformed {
  @apply rotate-180;
}

.icon path {
  fill: theme("colors.apple.light");
}

.iconDark path {
  fill: theme("colors.standarts.black");
}

.buttonWrap {
  width: 150px;
}

.loading {
  width: 300px;
}

.loadingClass {
  border-left: 2px solid theme("colors.apple.light") !important;
  border-top: 2px solid transparent !important;
  border-bottom: 2px solid transparent !important;
  border-right: 2px solid transparent !important;
  width: 30px !important;
  height: 30px !important;
}
