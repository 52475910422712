.container {
  @screen desktop {
    @apply relative mx-auto w-[1024px];
  }

  @screen onlyTablet {
    @apply relative mx-auto w-[640px];
  }
}

.wrapper {
  @screen tablet {
    @apply w-full bg-wild-sand pt-[40px] pb-[25px];
  }
}

.tabWrapper {
  @apply m-0;

  @screen tablet {
    @apply mt-[35px] mb-[30px];
  }
}

.logoWrapper {
  @apply px-[30px];

  @screen tablet {
    @apply w-[192px] h-[50px] p-0;
  }
}

.line {
  @apply border-alto;
}

.listWrapper {
  @apply px-[15px];

  @screen tablet {
    @apply flex  my-[30px] p-0;
  }
}

.content {
  @apply flex px-[15px] flex-col items-start self-stretch;

  @screen tablet {
    @apply w-1/5 p-0;
  }
}

.category {
  @apply block text-xs mt-[15px] pr-[35px];

  @screen mobile {
    @apply text-black text-[10px] font-semibold items-center whitespace-nowrap m-0 pr-0;
  }
}

.listTitle {
  @apply font-semibold font-os text-[13px] mt-2.5 text-nevada;

  @screen tablet {
    @apply font-bold text-base text-black m-0;
  }
}

.listItemsWrapper {
  @screen mobile {
    @apply flex items-center content-center gap-y-0 gap-x-2 flex-wrap;
  }
}

.divider {
  @apply block;

  @screen tablet {
    @apply hidden;
  }
}

.socialWrapper {
  @apply hidden;

  @screen onlyTablet {
    @apply flex flex-wrap justify-center gap-[25px] px-[30px] py-[15px];
  }

  @screen desktop {
    @apply flex justify-between px-[30px] py-[15px] bg-white rounded;
  }
}

.socialLogoWrapper {
  @apply flex;
}

.socialLogoWrapper * {
  @apply mr-[20px] mt-[15px];
}

.mobileCommunicationWrapper {
  @apply flex  px-[15px] flex-col items-start self-stretch;

  @screen tablet {
    @apply hidden;
  }
}

.communicationWrapper {
  @apply mt-[25px];
}

.communicationButtonWrapper {
  @apply flex w-[110px] h-[40px];
}
.communicationButton {
  @apply font-bold text-apple-light text-sm;
}

.mobilappLogoWrapper {
  @apply flex justify-between mt-[30px];
}

.contactUs {
  @apply ml-[10px] mr-[15px];
}

.contactUs > p {
  @apply text-xs;
}

.contactUs > span {
  @apply font-bold;
  font-size: 15px;

  @screen onlyTablet {
    @apply text-[13px];
  }
}

.brandsWrapper {
  @apply flex justify-center mt-5;

  @screen onlyTablet {
    @apply flex text-scorpion justify-center items-center mt-[33px] text-[7px];
  }

  @screen desktop {
    @apply flex text-scorpion mt-[33px] text-[12px];
  }
}

.ilab {
  @apply flex items-center;
}

.ilab a {
  @apply mx-[6px];
}

.brands {
  @apply hidden;

  @screen onlyTablet {
    @apply flex ml-[20px];
  }

  @screen desktop {
    @apply flex ml-[40px];
  }
}

.brand {
  @screen onlyTablet {
    @apply px-[4px];
  }

  @screen desktop {
    @apply px-[8px];
  }
}

.information {
  @apply px-[15px] py-[20px] text-center text-gray text-[11px];

  @screen tablet {
    @apply mt-[21px] leading-[1.45] p-0 text-start;
  }
}

.communicationInfo {
  @apply hidden;

  @screen tablet {
    @apply flex w-1/5;
  }
}

.communicationLogo {
  @apply mb-auto;

  @screen onlyTablet {
    @apply hidden;
  }
}

.mediaTitle {
  @apply text-base font-medium;
}

.mediaLinksWrapper {
  @apply flex items-center;
}

.mediaLinksWrapper *:not(:nth-child(1)) {
  @apply ml-[20px];
}

.downloadLinksWrapper {
  @apply flex items-center h-[50px];
}

.downloadLinksWrapper *:not(:nth-child(3)) {
  @apply mr-[15px];
}

.socialMediaLinks {
  @apply w-[35px] h-auto flex justify-center items-center;
}
.socialMediaLinks svg {
  @apply w-full h-auto;
}

.emlakjetLogo {
  @apply hidden;

  @screen tablet {
    @apply block;
  }
}

.mobileEmlakjetLogo {
  @apply block;

  @screen tablet {
    @apply hidden;
  }
}

.brandsAndInformationWrapper {
  @apply flex flex-col-reverse mb-7;

  @screen tablet {
    @apply flex flex-col;
  }
}
