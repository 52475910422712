.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  @apply border-4 border-alto;
  border-top: 4px solid theme("colors.apple.dark");
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
