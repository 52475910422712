.socialandDownloadLinksWrapper {
  @apply flex flex-col self-stretch;

  @screen tablet {
    @apply hidden;
  }
}

.Wrapper {
  @apply flex flex-col items-start self-stretch gap-2.5 px-[15px];
}

.iconLayout {
  @apply flex items-center gap-4;
}

.title {
  @apply font-semibold text-[13px] text-nevada mt-2.5;
}

.DownloadLinks {
  @apply flex gap-[5px];
}

.text {
  @apply text-nevada text-[11px] font-bold;
}

.line {
  @apply border-alto mt-[15px] mb-[5px];
}
