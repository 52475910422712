.btn {
  @apply text-white w-full font-medium rounded-full text-sm text-center font-os focus:outline-none focus:bg-apple-dark bg-apple-light;
  transition: all 0.2s ease-in-out;
}

.sizeSmall {
  height: 20px !important;
}

.sizeMedium {
  height: 40px !important;
}

.sizeLarge {
  height: 80px !important;
}
.sizeAuto {
  @apply h-full;
}
.link:active .btn,
.btn:hover {
  @apply bg-apple-dark;
}

.outline {
  @apply text-apple-light border-solid border border-apple-light;
  background-color: theme("colors.white") !important;
}

.link:active .outline,
.outline:hover {
  @apply text-apple-dark border-apple-dark;
  background-color: theme("colors.white") !important;
}

.disable {
  @apply text-white pointer-events-none w-full h-full font-medium rounded-full text-sm text-center font-os;
  background-color: theme("colors.alto") !important;
}

.link:active .disable,
.disable:hover {
  @apply outline-none;
  background-color: theme("colors.alto") !important;
}

.shadow {
  @apply text-apple-light border-solid border-2 border-alto;
  background-color: theme("colors.white") !important;
  box-shadow: 0 1px 5px 0 rgba(theme("colors.black"), 0.15);
  border-width: 1px;
}

.shadow:hover {
  @apply text-apple-dark;
  background-color: theme("colors.white") !important;
}

.loading {
  @apply border-2 border-alto;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  width: 30px;
  height: 30px;
}

.isLoading {
  opacity: 0.6;
}
