.wrapper {
  animation: rotateHandler 1s infinite;
}

@keyframes rotateHandler {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
