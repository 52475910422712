.menu {
  @apply flex flex-row;
  margin-left: 27px;
}

.menu > li {
  @apply relative;
}

.menu > li > div {
  @apply relative font-semibold flex cursor-pointer items-center;
  padding: 0 10px;
  font-size: 14px;
  line-height: 19px;
  color: theme("colors.standarts.black");
  height: 70px;
}

.icon {
  @apply inline-block float-right transition-transform;
  margin-left: 2px;
}

.iconTransformed {
  @apply rotate-180;
}
