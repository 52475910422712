.wrapper {
  position: relative;
  text-align: center;
  display: inline-flex;
}
.hideButton {
  font-size: 12px;
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.hideButtonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hideIcon {
  @apply rotate-180;
}
