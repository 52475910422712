.wrapper {
  @apply px-[15px] mb-2.5;

  @screen tablet {
    @apply flex mt-[30px] px-0 mb-0;
  }
}

.contentWrapper {
  @screen onlyTablet {
    @apply mr-6 text-sm;
  }

  @screen desktop {
    @apply w-1/5;
  }

  @screen mobile {
    @apply flex px-[15px] flex-col items-start self-stretch;
  }
}

.categoryName {
  @apply block text-xs mt-[15px];

  @screen mobile {
    @apply text-black text-[10px] font-semibold items-center whitespace-nowrap m-0;
  }
}

.title {
  @apply font-semibold font-os text-[13px] text-nevada mt-2.5;

  @screen tablet {
    @apply font-semibold font-os text-black mt-0 onlyTablet:text-xs;
  }
}

.categorys {
  @screen mobile {
    @apply flex items-center content-center gap-y-0 gap-x-2 flex-wrap;
  }
}

.divider {
  @apply block;

  @screen tablet {
    @apply hidden;
  }
}
